
  import { client } from '@/client';
  import { defineComponent } from 'vue';
  import { ElMessage } from 'element-plus';
import { ReqAdd } from '@/shared/adminprotocols/user/PtlAdd';
  export default defineComponent ({
    name: "admin-add",
    data: () => {
      return {
        hasWechat:1,
        params: {
          nickName: "",
          diamond:0,
          account:"",
          password: "",
          province: "",
          country: "",
          city: "",
        },
        paramsRules: {
          account: [
            { required: true, message: "请输入登录账号", trigger: "blur" },
            {
                min: 2,
                max: 20,
                pattern:/^[a-zA-Z0-9]*$/,
                message: "2到20个由字母与数字组成的字符",
                trigger: "blur",
            },
          ],
          code: [
            { required: true, message: "请输入登录账号", trigger: "blur" },
            {
                min: 6,
                max: 6,
                pattern:/^[a-zA-Z0-9]*$/,
                message: "6个由字母与数字组成的字符",
                trigger: "blur",
            },
          ],
          nickName: [
            { required: true, message: "请输入用户名", trigger: "blur" },
            {
              min: 2,
              max: 20,
              message: "2到20个字符",
              trigger: "blur",
            },
          ],
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 20,
              message: "6到20个字符",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      //新增
      async create() {
        try {
          let data:ReqAdd = {
            nickName: this.params.nickName,
            password: this.params.password,
            account:this.params.account,
            diamond:this.params.diamond,
          };
          if (this.hasWechat) {
             data.address = {
              country:this.params.country,
              province:this.params.province,
              city:this.params.city
             }
          }
          let res = await client.callApi("user/Add",data);
          if (res.isSucc) {
            ElMessage({
              message: "更新成功^_^",
              type: "success",
            });
            this.$router.go(-1);
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      submit(formName: 'params') {
        let refs:any = this.$refs[formName];
        if (refs) {
          refs.validate((valid: any) => {
            if (valid) {
              this.create();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      },
    },
  });
  