import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 mt-30 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px",
      class: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "登录账号",
          prop: "account"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.account,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.account) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "密码",
          prop: "password"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.password) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "昵称",
          prop: "nickName"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.nickName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.nickName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "充值钻石",
          prop: "diamond"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.diamond,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.diamond) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, {
          label: "添加地理",
          prop: "money"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.hasWechat,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hasWechat) = $event)),
              class: "ml-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  label: "1",
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("否")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_radio, {
                  label: "2",
                  size: "large"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("是")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        (_ctx.hasWechat == 2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "国家",
              prop: "country"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.country,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.country) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasWechat == 2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: "省份",
              prop: "province"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.province,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.params.province) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasWechat == 2)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              label: "城市",
              prop: "city"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.city,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.params.city) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}